import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const Shoyotathon23 = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="Shoyotathon '23" />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel="stylesheet" href="../styles/global.css" />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>Shoyotathon '23</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Dec. 5, 2022) </p>
        </div>
        <div className="articleBody">
          <p>
            <span className="editorsNote">VOLTAGE</span> refers to the{" "}
            <i>force</i> with which electrical energy travels through a
            conductor.
          </p>
          <p>
            <span className="editorsNote">AMPERAGE</span> refers to the{" "}
            <i>amount</i> of electrical energy traveling through a conductor.{" "}
          </p>
          <p>
            <span className="editorsNote">WATTAGE</span> also commonly called{" "}
            <span className="editorsNote">POWER</span> refers to the{" "}
            <span className="editorsNote">VOLTAGE</span> times the{" "}
            <span className="editorsNote">AMPERAGE</span>!!! In other words,
            <span className="editorsNote">WATTAGE</span> is the measure of the
            movement of electrical energy along two dimensions: how <i>much</i>{" "}
            energy there is and how <i>forcefully</i> it’s traveling.
          </p>
          <p>
            In 1992, Toyota announced what they called an “Earth Charter”, an
            internal mandate to begin researching and developing a low-emission,
            fuel-efficient vehicle in the interest of offsetting the harm to the
            environment caused by the automotive industry.
          </p>
          <p>
            In 1997, Toyota blazed a new trail in passenger vehicles when they
            released the fruits of their efforts, a subcompact car they named
            the Prius (a fitting name, from the Latin “first” or “original” or
            “to go before”). Though only modestly successful from a business
            standpoint (they sold a little over 100K copies over six years),
            Toyota’s vision to pioneer energy-conscious hybrid cars was decades
            ahead of its time.
          </p>
          <p>
            In 2003, Toyota redesigned the Prius from the ground up — this
            second generation Prius is what cemented Toyota as the industry
            leader in futuristic auto manfacturing. Not only was this new Prius
            an engineering marvel inside and out, it was much more commercially
            viable than its predecessor, selling over a million copies over that
            same six-year span. The Prius was a hit amongst technophiles, tree
            huggers, and pennypinchers alike.
          </p>
          <p>
            Today, almost 20 years since then, damn near every automaker{" "}
            <i>except</i> Toyota has entered the electric vehicle arms race.
            Why? How? Given Toyota’s history of innovation in this category, you
            would expect them to be perfectly suited to eat up most of the EV
            market share now that everyone and their grandma considers the
            climate collapse a top priority. Not only are they not in the race,
            they're ranked dead last in the{" "}
            <a
              href="https://www.greenpeace.org/eastasia/press/7611/nissan-and-honda-drop-in-greenpeace-auto-ranking/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Greenpeace Auto Ranking
            </a>
            .
          </p>
          <p>
            I, as a humble multi-trillionaire, felt morally obligated to step
            in.
          </p>
          <p>
            Many nights I sit at this keyboard without any idea what will pour
            out. My job is the sitting at the keyboard, the pouring is done by
            something else entirely.
          </p>
          <p>
            In celebration of the birth of Shoyota, I’m happy to announce
            Shoyotathon ‘23: a yearlong blowout sale where all gas-powered
            Toyota vehicles will be on sale for 100 dollars! That’s right, 100
            dollars! That oughta help the environment 😎
          </p>
          <p>
            I’m also pleased to announce the first vehicle in the all new 2023
            lineup of all new EV’s: the all new Shoyota 9Runner, a 4x4
            all-terrain SUV with 1200 horsepower, 700-mile range, fully
            autonomous driving, and the most luxurious interior ever designed.
            All for $23,000 MSRP.
          </p>
        </div>
        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020-</p>
        </div>
      </div>
    </div>
  )
}
export default Shoyotathon23
